export function enableVideoAutoplay() {
	// Get all the talkie video elements
	const talkieVideos = document.querySelectorAll('.single-media video');

	// Create an Intersection Observer instance
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0) {
				// Video is in view, play it
				// entry.target.play();
			} else {
				// Video is out of view, pause it
				entry.target.pause();
			}
		});
	});

	// Loop through each talkie video and observe it
	talkieVideos.forEach((video) => {
		observer.observe(video);
	});
}