export function newsletterForm() {
    var frm = 'subscribeform';
    var frmInp = 'emailaddress';
    console.log('Newsletter Loaded');

    var isvalid = true;
    $('form.subscribe').on('submit', function (ev) {
        ev.preventDefault();
        $(frm).html('').css('opacity', 0);
        var frm = this;
        var emailInput = $(this).find('#emailaddress')[0];
        if (ValidateField($(emailInput))) {
            $.when(
                $.ajax({
                    url: "/umbraco/surface/Home/SaveNewsletterSubscription",
                    type: "POST",
                    data: { emailaddress: $(emailInput).val() },
                    //contentType: "application/json; charset=utf-8",
                    success: function (response) {
                        $('div.cta > form').html(response);
                    }
                })
        )
        .then(function () {
            setTimeout(() => {
                $(frm).css('opacity', 1);
            }, 500);
        });
    return;
} else {
    $(emailInput).addClass('error');
}
    });

//$('#' + frmInp).on('focus', function () { $(this).val(''); });

function ValidateField(ele) {
    if ($(ele).val() === '') {
        return false;
    } else {
        return true;
    }
}
}
