import {checkVideosInitiated} from './brightcove-videos';

export function handleVideoLoad(vidParent) {
	// console.log("handleImageLoad started");

	const lazyloadVideos = vidParent.querySelectorAll(".lazyload-video");

	lazyloadVideos.forEach(function (videoWrapper) {
		// console.log("vidParent: ", vidParent);
		// console.log("lazyloadVideos: ", lazyloadVideos);
		// console.log("videoWrapper: ", videoWrapper);



		// Handle the high-res video load event
		function handleVideo() {
			// console.log("This video is loaded");
			
			let shouldWeShowTheImage = false;
			
			// Double check the space is in the viewport
			if(vidParent.classList.contains("in-viewport")) {
				// console.log("It's in the viewport", vidParent);
				shouldWeShowTheImage = true;
			}
			
			// Double check the video has loaded
			// console.log("highResImage", videoWrapper);
			if (videoWrapper.classList.contains("loaded")) {
				// console.log("It's already loaded", videoWrapper);
				shouldWeShowTheImage = false; // It's already done
			}

			if (shouldWeShowTheImage) {
				// console.log("videoWrapper: ", videoWrapper);
				// console.log("videoWrapper: ", videoWrapper);
				// addPlayer(bcVideoId, thisPlayerWrapper);

				checkVideosInitiated(videoWrapper);

				// videoWrapper.classList.add("loaded");
				vidParent.classList.add("vid-handled");
			}
		}

		// Immediately run the above function
		handleVideo();

	});

	// console.log("handleImageLoad done");
}