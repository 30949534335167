let mouse = {x:0, y:0}; //mouse.x, mouse.y

let backdrops = document.getElementsByClassName("project-click"); // list of divs
let followersInitialPos = [];
let followersCurrentPos = [];
const startPos = {x:0, y:0};
let followersDistance = [];
let browserHasResized = false;

// This builds an array of position objects. One for each project-click
// These objects are updated later
const backdropsArray = Array.from(backdrops);
backdropsArray.forEach((elem, index) => {
	followersDistance.push(startPos);
});


for (let index=0; index < backdrops.length; index++) {
	let thisFollower = backdrops[index].getElementsByClassName("info")[0];
	let thisWrapper = backdrops[index].getElementsByClassName("inner")[0];
	// let thisBackdropPos = getPos(thisWrapper);
	// let thisFollowrPos = getPos(thisFollower);
	let thisWrapperWidth = thisWrapper.getBoundingClientRect().width;
	let thisWrapperHeight = thisWrapper.getBoundingClientRect().height;

	// let thisFollowerRelativePosX = thisFollowrPos.x - thisBackdropPos.x;
	let thisFollowerRelativePosX = thisWrapperWidth / 2 - thisFollower.getBoundingClientRect().width / 2;
	// let thisFollowerRelativePosY = thisFollowrPos.y - thisBackdropPos.y;
	let thisFollowerRelativePosY = thisWrapperHeight / 2 - thisFollower.getBoundingClientRect().height / 2;
	
	// console.log("thisWrapperWidth: ", thisWrapperWidth);
	// console.log("thisFollowerRelativePosX: ", thisFollowerRelativePosX);


	// We'll get and set the initial values of the followers co-ords so that it can snap back to it's origin
	// We should not update these values
	followersInitialPos[index] = {x:thisFollowerRelativePosX, y:thisFollowerRelativePosY};

	// We'll also set this one, but we expect this one to constantly update from here on out
	followersCurrentPos[index] = {x:thisFollowerRelativePosX, y:thisFollowerRelativePosY};

	// We'll also go ahead and apply those numbers to the follower before using any other function
	thisFollower.style.left = thisFollowerRelativePosX + "px";
	thisFollower.style.top = thisFollowerRelativePosY + "px";

}

// This gets an elements position relative to the entire web page (as opposed to the viewport)
function getPos(el) {
	for (var lx=0, ly=0;
		  el != null;
		  lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
	return {x: lx,y: ly};
}

function getMouse(e){
	for (let item of backdrops) {

		var itemInner = item.getElementsByClassName("inner")[0];

		var backdropPos = getPos(itemInner);

		if(itemInner.matches(":hover")) {
			mouse.x = e.pageX - backdropPos.x;
			mouse.y = e.pageY - backdropPos.y;
		}
	}
}

function followMouse(){

	for (let index=0; index < backdrops.length; index++) {

		const inertia = 12; // This sets the speed that the cursor "rubberbands" back to the middle
		const inertiaOnHover = 2; // This sets the speed that the cursor "rubberbands" to the mouse

		let item = backdrops[index];
		const itemInner = item.getElementsByClassName("inner")[0];
		const follower = item.getElementsByClassName("info")[0];

		const followerWidth = follower.getBoundingClientRect().width;
		const followerHeight = follower.getBoundingClientRect().height;
		const wrapperWidth = itemInner.getBoundingClientRect().width;
		const wrapperHeight = itemInner.getBoundingClientRect().height;

		let cursorsCenteredPos = {x:0, y:0};
		cursorsCenteredPos.x = mouse.x - (followerWidth / 2);
		cursorsCenteredPos.y = mouse.y - (followerHeight / 2);

		// Update X
		if (followersInitialPos[index].x < 100 || browserHasResized) {
			const thisFollowerRelativePosX = wrapperWidth / 2 - followerWidth / 2;
			followersInitialPos[index].x = thisFollowerRelativePosX;
			browserHasResized = false;
		}

		// Update Y
		if (followersInitialPos[index].thisFollowerRelativePosY < 100 || browserHasResized) {
			const thisFollowerRelativePosY = wrapperHeight / 2 - followerHeight / 2;
			followersInitialPos[index].y = thisFollowerRelativePosY;
			browserHasResized = false;
		}

		//1. find the distance between the follower and the mouse
		if (itemInner.matches(":hover")) {
			followersDistance[index].x = cursorsCenteredPos.x - followersCurrentPos[index].x;
			followersDistance[index].y = cursorsCenteredPos.y - followersCurrentPos[index].y;

			//Easing motion -- Progressive reduction of distance 
			followersCurrentPos[index].x += followersDistance[index].x/inertiaOnHover;
			followersCurrentPos[index].y += followersDistance[index].y/inertiaOnHover;
		} else {
			followersDistance[index].x = followersInitialPos[index].x - followersCurrentPos[index].x;
			followersDistance[index].y = followersInitialPos[index].y - followersCurrentPos[index].y;

			//Easing motion -- Progressive reduction of distance 
			followersCurrentPos[index].x += followersDistance[index].x/inertia;
			followersCurrentPos[index].y += followersDistance[index].y/inertia;
		}

		follower.style.left = followersCurrentPos[index].x + "px";
		follower.style.top = followersCurrentPos[index].y + "px";
		follower.classList.add("floating");
	}
}

export function cursorFollow(){
	// alert("If this message popped up. The script was successfully importd into the main.js");

	document.addEventListener("mousemove", getMouse); 
	setInterval(followMouse, 0);

	window.addEventListener('resize', function(event) {
		browserHasResized = true;
	}, true);
	window.addEventListener('orientationchange', function(event) {
		browserHasResized = true;
	}, false);
}

