// Prototype on https://codepen.io/sirpixelot/pen/XWxxxyx?editors=1010

// const idPrefix = "vidId-";
const idPrefix = "";

const playerData = {
	'accountId': '5972928229001',
	'playerId': 'z8qNdiYpJ',
	// Try and pass in these values from the appsettings.json so we have one source of truth for where they are set.
};

export function initPlayer(playerElemId) {
	// console.log("initialise: ", playerElemId);
	// bc(playerElemId).play();
	bc(playerElemId);
}

export function addPlayer(bcVideoId, thisPlayerWrapper) {
	// console.log("add stuff for: ", bcVideoId);
	const elemId = idPrefix + bcVideoId;

	thisPlayerWrapper.setAttribute('autoplay', 'true');
	// Dynamically build the player video element
	const playerHTML = '<video id=\"'+elemId+'\" data-video-id=\"' + bcVideoId + '\" data-account=\"' + playerData.accountId + '\" data-player=\"' + playerData.playerId + '\" data-embed=\"default\" class=\"video-js\"></video>';

	// const thisPlayerWrapper = document.querySelector("#" + bcVideoId);
	// console.log("thisPlayerWrapper: ", thisPlayerWrapper);
	// console.log("playerHTML: ", playerHTML);
	// Inject the player code into the DOM
	thisPlayerWrapper.innerHTML += playerHTML;
	initPlayer(thisPlayerWrapper);
 }

export function checkVideosInitiated(videoParent) {
	//console.log("Running main brightcove function on: ", videoParent);

	let videos;

	if (videoParent) {
		// console.log("a single video was passed in ", videoParent);
		videos = videoParent.querySelectorAll('video-js');
	} else {
		// console.log("no videos passed in. Check all");
		videos = document.querySelectorAll('video-js');
	}

	// console.log("videos: ", videos);
	for (let index = 0; index < videos.length; ++index) {
		const thisVid = videos[index];
		const thisVidId = thisVid.getAttribute('id');
		const thisVidPaused = thisVid.classList.contains("vjs-paused");
		// console.log("This vid is: ", thisVid);

		if (thisVid.children.length == 0) {
			// console.log("This video needs stuff adding: ", thisVidId);

			addPlayer(thisVidId, thisVid);
		} else {
			// console.log("Skip this: ", thisVidId);
		}

		if (thisVidPaused) {
			const thisVidsPlayer = thisVid.querySelector("video");
			// console.log("this vid is paused", thisVidsPlayer);

			thisVidsPlayer.play();
		} else {
			// console.log("this vid is playing");
		}

	}

}



