import {handleImageLoad} from './handleImageLoad';
import {handleVideoLoad} from './handleVideoLoad';

export function observeScroll() {

	// Get the target elements
	const mainHeader = document.querySelector('header.article-header');
	const main = document.querySelector('main');
	const psuedoFooter = document.querySelector('main > .psuedo-footer');

	const projectsGrid = document.querySelector('.projects-grid');
	const componentsList = document.querySelectorAll('.umb-block-list > div:not(.img-handled)');

	const viewportTop = window.pageYOffset || document.documentElement.scrollTop;
	const viewportBottom = viewportTop + window.innerHeight;

	function performScrollActions(scrollElem) {
		//console.log("scrollElem: ", scrollElem);
		const elementRect = scrollElem.getBoundingClientRect();
		const elementTop = elementRect.top + viewportTop;
		const elementBottom = elementRect.bottom + viewportTop;

		// scrollElem.classList.remove('active'); // Remove active class from all components

		if (elementTop > viewportBottom) {
			// Top of the element is below the bottom of the viewport
			// scrollElem.classList.add('s1a', 'etop-below-vbottom');
			// scrollElem.classList.remove('s1b', 'etop-above-vbottom');

			scrollElem.classList.remove('in-viewport', '⬆️', '⬇️', '🪟');

		} else {
			// Top of the element is above the bottom of the viewport
			// scrollElem.classList.add('s1b', 'etop-above-vbottom');
			// scrollElem.classList.remove('s1a', 'etop-below-vbottom');

			scrollElem.classList.add('scroll-entered');

			// Why are just trying to check if the element is in the viewport at all
			//     So the    top of the element is above the bottom of the viewport
			// AND the    bottom of the element is below the top    of the viewport
			if (elementBottom > viewportTop) {
				scrollElem.classList.add('in-viewport');
				// console.log("let's handle images in: ", scrollElem);
				if (scrollElem.tagName !== "MAIN") {
					// console.log("It's NOT main. Go ahead");
					handleImageLoad(scrollElem);
					handleVideoLoad(scrollElem);
				}
			} else {
				scrollElem.classList.remove('in-viewport', '⬆️', '⬇️', '🪟');
			}
		}


		if (elementTop < viewportTop) {
			// Top of the element is above the top of the viewport
			// scrollElem.classList.add('s2a', 'etop-above-vtop');
			// scrollElem.classList.remove('s2b', 'etop-below-vtop');

			if (scrollElem.classList.contains("main")) {
				if (mainHeader != null) {
					mainHeader.classList.add('header-hiding');
					mainHeader.classList.remove('header-showing');
				}
			}

			scrollElem.classList.add('etop-above-vtop');
			scrollElem.classList.remove('etop-below-vtop');
		} else {
			// Top of the element is below the top of the viewport 
			// scrollElem.classList.add('s2b', 'etop-below-vtop');
			// scrollElem.classList.remove('s2a', 'etop-above-vtop');

			if (scrollElem.classList.contains("main")) {
				if (mainHeader != null) {
					mainHeader.classList.add('header-showing');
					mainHeader.classList.remove('header-hiding');
				}
			}

			scrollElem.classList.add('etop-below-vtop');
			scrollElem.classList.remove('etop-above-vtop');
		}

		if (elementBottom > viewportBottom) {
			// Bottom of the element is below the bottom of the viewport
			// scrollElem.classList.add('s3a', 'ebottom-below-vbottom');
			// scrollElem.classList.remove('s3b', 'ebottom-above-vbottom');
		} else {
			// Bottom of the element is above the bottom of the viewport
			// scrollElem.classList.add('s3b', 'ebottom-above-vbottom');
			// scrollElem.classList.remove('s3a', 'ebottom-below-vbottom');
		}

		if (elementBottom < viewportTop) {
			// Bottom of the element is above the top of the viewport
			// scrollElem.classList.add('s4b', 'ebottom-above-vtop');
			// scrollElem.classList.remove('s4a', 'ebottom-below-vtop');
		} else {
			// Bottom of the element is below the top of the viewport
			// scrollElem.classList.add('s4a', 'ebottom-below-vtop');
			// scrollElem.classList.remove('s4b', 'ebottom-above-vtop');
		}
	}

	// Function to check and apply actions based on element position
	function checkElementPosition() {
		main.classList.add('scroll-ready');

		if (mainHeader != null) {
			// console.log("mainHeader scroll actions");
			performScrollActions(mainHeader);
		}
		if (main != null) {
			// console.log("main scroll actions");
			performScrollActions(main);
		}
		if (psuedoFooter != null) {
			// console.log("psuedoFooter scroll actions");
			performScrollActions(psuedoFooter);
		}
		if (projectsGrid != null) {
			// console.log("projectsGrid scroll actions");
			performScrollActions(projectsGrid);
		}

		for (var index = 0; index < componentsList.length; index++) {
			componentsList[index].classList.add('scroll-ready');
			performScrollActions(componentsList[index]);
		}
	}

	// Call the function on scroll event
	window.addEventListener('scroll', checkElementPosition);

}