export function reportDownloadFormHandler() {
    if (document.forms["report-form"] === undefined && document.forms["report-purchase-form"] === undefined) { return; }
    else if (document.forms["report-form"] != undefined) {
        Array.from(document.forms["report-form"].elements).forEach((input) => {
            input.addEventListener('change', function () { if (this.value.length > 0) { $(this).removeClass('error'); $(this).parent().removeClass('error'); } });
        });
    } else if (document.forms["report-purchase-form"] != undefined) {
        Array.from(document.forms["report-purchase-form"].elements).forEach((input) => {
            input.addEventListener('change', function () { if (this.value.length > 0) { $(this).removeClass('error'); $(this).parent().removeClass('error'); } });
        });
    }

    $('div.form-row').removeClass('error');

    $('#report-form').on('submit', function (e) {
        e.preventDefault();
        if (ValidateForm('report-form')) {
            var source = "";
            var data = JSON.stringify({ __RequestVerificationToken: $("[name='__RequestVerificationToken']").val(), EmailAddress: $('#report-form #EmailAddress').val(), FullName: $('#report-form #FullName').val(), Company: $('#report-form #Company').val(), Position: $('#report-form #Position').val(), ReportPage: window.location.href, PageId: $('#report-form #PageId').val() });

            $.ajax({
                url: "/umbraco/surface/Reports/SaveKlaviyoForm",
                type: "POST",
                data: data,
                contentType: "application/json",
                success: function (reportLink) {
                    document.forms["report-form"].reset();
                    $('div#report-form > div').slideToggle();
                    var a = document.createElement("a");
                    a.href = reportLink;
                    var evt = document.createEvent("MouseEvents");
                    a.setAttribute('download', '');

                    //the tenth parameter of initMouseEvent sets ctrl key
                    evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null);
                    a.dispatchEvent(evt);
                }
            });
        }
    });

    $('#report-purchase-form').on('submit', function (e) {
        var valid = false;
        if (!ValidateForm('report-purchase-form')) {
            e.preventDefault();
            var data = JSON.stringify({ __RequestVerificationToken: $("[name='__RequestVerificationToken']").val(), EmailAddress: $('#report-purchase-form #EmailAddress').val(), FullName: $('#report-purchase-form #FullName').val(), Company: $('#report-purchase-form #Company').val(), Position: $('#report-purchase-form #Position').val(), ReportPage: window.location.href, PageId: $('#report-purchase-form #PageId').val(), ReportType: 'Paid', PageId: $('#report-purchase-form #Price').val() });
            valid = true;
            // /umbraco/surface/reports/CreateCheckoutSession
            // $(this).unbind('submit').submit();
            // $.ajax({
            //     url: "/umbraco/surface/Reports/SaveKlaviyoForm",
            //     type: "POST",
            //     data: data,
            //     contentType: "application/json",
            //     success: function(reportLink) {
            //         document.forms["report-form"].reset();
            //         $('div#report-form > div').slideToggle();
            //          var a = document.createElement("a");
            //          a.href = reportLink;
            //          var evt = document.createEvent("MouseEvents");
            //          a.setAttribute('download', '');

            //          //the tenth parameter of initMouseEvent sets ctrl key
            //          evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0,true, false, false, false, 0, null);
            //          a.dispatchEvent(evt);
            //     }
            // });
        }
    });

    function ValidateForm(frm) {
        var isvalid = true;
        try {
            Array.from(document.forms[frm].elements).forEach((input) => {
                if (input.hasAttribute('required') && input.value === '') {
                    $(input).parent().removeClass('error').addClass('error');
                    $(input).removeClass('error').addClass('error');
                    isvalid = false;
                }
            });
        } catch { }
        if (!$('#' + frm + ' #frmterms').is(':checked')) {
            $('#' + frm + ' #frmterms').parent().removeClass('error').addClass('error');
            $('#' + frm + ' #frmterms').removeClass('error').addClass('error');
            isvalid = false;
        }

        return isvalid;
    }

    // Download file if payment made
    setTimeout(function() {
        if($('a#downloadfile').length > 0) {
            var a = document.createElement("a");
            a.href = $('a#downloadfile').attr('href');
            var evt = document.createEvent("MouseEvents");
            a.setAttribute('download', '');

            //the tenth parameter of initMouseEvent sets ctrl key
            evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null);
            a.dispatchEvent(evt);
}        
    }, 3000);
}
