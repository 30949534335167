export function mediaCarousel() {
    var title = "";
    $(document).mousemove(function (e) {
        $(".media-carousel").each(function (i, v) {

            var container = v;
            var img = $(this).children()[0];

            if ((e.pageY < $(img).offset().top ||
                e.pageY > $(img).offset().top + $(img).height() ||
                e.pageX < $(img).offset().left ||
                e.pageX > $(img).offset().left + $(img).width())) {

                if ($(container).children().length == 2) {
                    $(container).children()[0].title = $($(container).children()[1]).html();
                    container.removeChild($(container).children()[1]);
                }
            }
            else {
                if ($(container).children().length == 1) {
                    title = $("<div class='carousel_title sans'>DRAG</div>");
                    $(container).children()[0].title = "";
                    $(container).append(title);
                }
                title.offset({
                    top: (e.pageY ? e.pageY + 5 : e.clientX + 5),
                    left: (e.pageX ? e.pageX + 5 : e.clientY + 5)
                });
            }
        });

    });
}