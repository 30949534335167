export function handleImageLoad(imgParent) {
	// console.log("handleImageLoad started");

	const lazyloadImages = imgParent.querySelectorAll(".lazyload-image");

	lazyloadImages.forEach(function (imageWrapper) {
		const lowResImage = imageWrapper.querySelector(".low-res");
		const highResImage = imageWrapper.querySelector(".high-res");
		
		
		// Check if the high-res image has already loaded
		if (highResImage.complete) {
			handleHighResImage();
		} else {
			// Attach event listener for when the high-res image loads
			// console.log("waiting for high res to load", highResImage);
			imageWrapper.classList.add("ready");
			highResImage.addEventListener("load", handleHighResImage);
		}

		// Handle the high-res image load event
		function handleHighResImage() {
			// console.log("This image is loaded");
			
			let shouldWeShowTheImage = false;
			
			// Double check the space is in the viewport
			if(imgParent.classList.contains("in-viewport")) {
				shouldWeShowTheImage = true;
			}
			
			// Double check the image has loaded
			// console.log("highResImage", imageWrapper);
			if (imageWrapper.classList.contains("loaded")) {
				shouldWeShowTheImage = false; // It's already done
			}

			if (shouldWeShowTheImage) {
				imageWrapper.classList.add("loading");
				imgParent.classList.add("img-handled"); // DO we need this?
				
				setTimeout(function() {
					imageWrapper.classList.add("loaded");
					imageWrapper.classList.remove("loading");
					
				}, 1500);
			}
		}
	});

	// console.log("handleImageLoad done");
}