import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Video } from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';

function cloneSplides(thisMarquee) {
    let viewportWidth = window.innerWidth;
    const thisTrack = thisMarquee.querySelector(".splide__list");
    //console.log('Players: ' + thisTrack);

    let thisTrackWidth = thisTrack.getBoundingClientRect().width;
    const originalChildren = Array.from(thisTrack.children);

    let loopMax = 100; // If this loop needs to run more than 100 times, there's probably something wrong.
    for (let cloneIndex = 0; cloneIndex < loopMax; cloneIndex++) {
        if (viewportWidth > thisTrackWidth) {
            for (let origChildIndex = 0; origChildIndex < originalChildren.length; origChildIndex++) {
                thisTrack.appendChild(originalChildren[origChildIndex].cloneNode(true));
            }
            thisTrackWidth = thisTrack.getBoundingClientRect().width;
        } else {
            break;
        }
    }
}

function slideVisible(target) {
    //console.log('Visible slide:', target);
    // Perform desired actions for visible slide
}

export function initSliders(speed) {
    const marquees = document.getElementsByClassName('splide ticker');
    const projSliders = document.getElementsByClassName('splide projects-splide');
    const roundabouts = document.getElementsByClassName('splide roundabout');
    
    // Initiate Marquees
    for (let i = 0; i < marquees.length; i++) {
        cloneSplides(marquees[i]);

        new Splide(marquees[i], {
            type: 'loop',
            arrows: false,
            focus: 'center',
            direction: $(marquees[i]).attr('dir') === 'rtl' ? 'rtl' : 'ltr',
            lazyLoad: 'nearby',
            drag: 'free',
            autoWidth: true,
            autoScroll: {
                speed: ($(marquees).hasClass('homepage') ? speed * 3 : speed),
            },
            pagination: false,
        })
        .mount({ AutoScroll, Video })
        .on('visible', slideVisible);
    }

    // Initiate Project Sliders
    for (let i = 0; i < projSliders.length; i++) {
        new Splide(projSliders[i], {
            type: 'loop',
            perPage: 4,
            padding: '5rem',
            focus: 'center',
            direction: $(projSliders[i]).attr('dir') === 'rtl' ? 'rtl' : 'ltr',
            lazyLoad: 'nearby',
            drag: 'free',
            autoWidth: false,
            autoScroll: {
                speed: speed,
            },
            autoHeight: true,
            video: {
                loop: true,
                mute: true,
            },
            breakpoints: {
                1000: {
                    perPage: 2,
                },
                640: {
                    perPage: 1,
                    padding: '2rem',
                },
                480: {
                    perPage: 1,
                    padding: '1rem',
                },
            },
        })
        .mount({ AutoScroll, Video })
        .on('visible', slideVisible);
    }


    // INITIATE ROUNDABOUTS
    for (var i = 0; i < roundabouts.length; i++) {

        // console.log("Initialising a new Splide on roundabout: ", roundabouts[0]);

        new Splide(roundabouts[i], {
            type: 'loop',
            arrows: false,
            focus: 'end',
            direction: ($(roundabouts[i]).attr('dir') === 'rtl' ? 'rtl' : 'ltr'),
            lazyLoad: 'nearby',
            drag: true,
            pagination: false,
            autoWidth: true,
            autoHeight: true,
            video: {
                loop: true,
                mute: true,
            },
        })
        .mount({ Video })
        .on('visible', slideVisible);
    }
}
