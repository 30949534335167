export function reportsPage() {
    $(window).scroll(function () {
        if ($(window).scrollTop() >= $(document).height() - $(window).height() - 250 && $(window).scrollTop() < $(document).height() - $(window).height() - 50) {
            LoadReports(GetParameters(), false);
        }
    });

    // Filters
    var ele = 'div.reports-list div.grid-filter-options input[type="checkbox"]';
    $(ele).on('change', e => {
        var newqsParam = '';
        $('div.reports-list-items').html('');
        $('div.reports-loader').removeClass('hidden');

        document.querySelectorAll(ele).forEach(chkBx => {
            if(chkBx.checked === true) {
                if(newqsParam === '') { newqsParam += $(chkBx).attr('data-val'); } else { newqsParam += ',' + $(chkBx).attr('data-val'); }
            }
            if(newqsParam != '') {
                window.history.pushState(null, null, window.location.origin + window.location.pathname + '?report-tag=' + newqsParam);
            } else {
                window.history.pushState(null, null, window.location.origin + window.location.pathname);
            }
            LoadReports(newqsParam, true);
        });
    });

}

export function LoadReports(params, firsttime) {
    $.ajax({
        url: "/umbraco/surface/Reports/LoadMoreReports",
        type: "GET",
        data: {tags: params, skipItems: $('div.reports-list-items > div').length, takeItems: 10},
        success: function(reports) {
            //console.log('success')
            if(firsttime) { $('div.reports-list-items').html(reports); } else { $('div.reports-list-items').append(reports); }
            $('div.reports-loader').addClass('hidden');
            if(!$('div.reports-list-items').hasClass('contents-loaded')) {
                $('div.reports-list-items').addClass('contents-loaded');
            }
        }
    });
}

export function GetParameters() {
    var urlParams;
    (window.onpopstate = function () {
        var match,
            pl     = /\+/g, 
            search = /([^&=]+)=?([^&]*)/g,
            decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
            query  = window.location.search.substring(1);

        urlParams = {};
        while (match = search.exec(query))
           urlParams[decode(match[1])] = decode(match[2]);
    })();

    return urlParams["report-tag"];
}
