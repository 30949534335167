export function viewportCheck(element, targetElement) {
	const classNameToAdd = "exceeds-viewport";
	let viewportHeight = window.innerHeight;
	const classList = targetElement ? targetElement.classList : element.classList;

	function checkElementHeight() {
		const elementHeight = element.offsetHeight;
		const elName = element.id || element.tagName.toLowerCase();
		//console.log("elName", elName);

		// When the element is taller than the viewport,
		// It adds the classname to the element passed in
		// Unless two were passed in, in which case it
		// adds a more consise classname to the optional
		// target element
		if (elementHeight > viewportHeight) {
			if (targetElement) {
				classList.add(`${elName}-${classNameToAdd}`);
			} else {
				classList.add(classNameToAdd);
			}
		} else {
			if (targetElement) {
				classList.remove(`${classNameToAdd}-${elName}`);
			} else {
				classList.remove(classNameToAdd);
			}
		}

	}

	// Initial check when the page loads
	checkElementHeight();

	// Recalculate on window resize
	window.addEventListener('resize', function () {
		viewportHeight = window.innerHeight;
		checkElementHeight();
	});
}
